
import router from '@/router'
import { defineComponent, ref, onMounted } from 'vue';
import { Modal } from 'ant-design-vue';
import { DownloadOutlined } from '@ant-design/icons-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/plugin/updateLocale';
import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import Constains from '@/components/Constains';
import { notification } from 'ant-design-vue';
//import * as xlsx from 'xlsx-js-style';
import * as xlsx from 'sheetjs-style';
  
  export default defineComponent({
    components: {
      datepicker: VueDatePicker,
      DownloadOutlined,
    },
    setup() {
      const cookies  = useCookies();
      const projectSource:any = ref([])
      const selectProject:any = ref([])
      const bushoSource:any = ref([])
      const selectBusho:any = ref([])
      const affiliationSource:any = ref([])
      const selectAffiliation:any = ref([])
      const rangeMode = ref()
      const selectMonth = ref()
      const selectRange = ref()

      onMounted(async() => {
        await _reload()
      });

      const whiteFrame = 'FFFFFF'
      const lightgrayFrame = 'd3d3d3'
      const grayFrame = '808080'
      const headerColor = '9cd4ac'
      const sumColor = 'b0dafe' 
      const projectsumColor = '64B7FE' 
      const totalColor = 'FFCC99'
      const differenceColor = '006AB6'
      const undifferenceColor = 'FF0000'

      const debugmode = false

      const debug = () => {
        const worksheet = xlsx.utils.json_to_sheet([])
        const baseColumn = 1;
        const daibunruiColumn = baseColumn + 1;
        const kubunColumn = daibunruiColumn + 1;
        const staffColumn = kubunColumn + 1;
        const planColumn = staffColumn + 1;
        const startRow = 2;
        let currentRow = startRow;

        //記入を開始
        worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] = {
          v:'合計 / 作業時間（時間）',t:'s',w:'合計 / 作業時間（時間）',
          s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: whiteFrame }},
            },
            fill: { fgColor: { rgb: headerColor } } 
          }
        }
        worksheet[xlsx.utils.encode_col(baseColumn+1)+currentRow] = { 
          v:'',t:'s',w:'', 
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: whiteFrame }},
          }}}
        worksheet[xlsx.utils.encode_col(baseColumn+2)+currentRow] = { v:'',t:'s',w:'', 
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: whiteFrame }},
          }}}
        worksheet[xlsx.utils.encode_col(baseColumn+3)+currentRow] = { v:'',t:'s',w:'', 
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
          }}}
        currentRow++
        worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] = {v:'工事名称',t:'s',w:'工事名称',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        }}}
        worksheet[xlsx.utils.encode_col(baseColumn+1)+currentRow] = {v:'作業名',t:'s',w:'作業名',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        }}}
        worksheet[xlsx.utils.encode_col(baseColumn+2)+currentRow] = {v:'区分名',t:'s',w:'区分名',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        }}}
        worksheet[xlsx.utils.encode_col(baseColumn+3)+currentRow] = {v:'社員名',t:'s',w:'社員名',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        }}}

        currentRow++
        currentRow++

        //合計の列の色を全て変える
        for(let changeColorColumn = 0; changeColorColumn < 10; changeColorColumn++ ){
          worksheet[xlsx.utils.encode_col(4 + changeColorColumn )+currentRow] =  {
          v: '', t:'s', w:'',
          s:  { border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: whiteFrame }},
            },
            fill: { fgColor: { rgb: sumColor } } 
          }}
        }

        //表示範囲を設定.これを入れないとデータを入れても表示されない
        const ref = "A1:"+ xlsx.utils.encode_col(baseColumn + 4) + currentRow;
        worksheet['!ref'] = ref
        
        //worksheet['!merges'] = merges; //マージ処理を反映
        worksheet['!cols'] = [{wch:2},{wch:31.75},{wch:25.25},{wch:15.38},{wch:7.75},] //カラムの横幅を変更
        
        const workbook = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(workbook,worksheet,"Datas")
        xlsx.writeFile(workbook,"Presidents.xlsx", { compression: true });
      }

      const _reload = async () => {
      var token = cookies.cookies.get('token');

      if(token == null){
        if(Constains.autoLogout){
          //ログインページに飛ばす
          router.push('/login');
          return;
          }
        }
      
      //ログインユーザー情報を取得
      try{
        let res = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token,{headers:{
            'Authorization' :'Bearer '+token, }});
        if(res.status == 200){
          let result = res.data;
          if(result.activate == 0) {
            
            if(Constains.autoLogout){
              notification.error({
                message: '非アクティブなアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
          }
         } else if(res.status==400) {
          //ログアウトしてログイン画面に
          if(Constains.autoLogout){
            cookies.cookies.remove('token');
              notification.error({
                message: '無効なアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
         }
        } catch (e) {
          notification.error({
                message: 'ログインに失敗しました.',
              })
         if(Constains.autoLogout) return
        }

        //開発計画を取得
        let res = await axios.get(Constains.tracerbaseURL+'/api/Projects',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            projectSource.value.push({
              value:result['id'],
              label:result['name'],
              abbre:result['abbreviation'],
              status:result["isfin"] == 0?'作業中':'完了',
              isfin:result["isfin"].toString()
            });
          }
        } 

        res = await axios.get(Constains.tracerbaseURL+'/api/Busho',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            bushoSource.value.push({
              value:result['id'],
              label:result['label'],
              edittype:result['edittype'],
            });
          }
        } 

        //会社を取得
        res = await axios.get(Constains.tracerbaseURL+'/api/Affiliations',{headers:{
          'Authorization' :'Bearer '+token,
        }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            affiliationSource.value.push({
              value:result['id'],
              label:result['label'],
            });
          }
        } 
      }

      let codeSource:any[] = []

      const get大分類 = (code:string) => {
        const index = code.indexOf('-')
        if(index < 0) return codeSource.filter(e=>e.code==code)[0]
        else {
          const daiCode = code.substring(0,index)
          return codeSource.filter(e=>e.code==daiCode)[0]
        }
      }

      const nippou = async() => {
        if(debugmode){
          debug()
          return
        }

        let start = ''
        let end = ''
        if(rangeMode.value == "2"){
          if(selectMonth.value===undefined){
            Modal.error({
              title: 'エラー',
              content: '出力する月が選択されていません.',
            });
            return
          }
          const month = selectMonth.value.month + 1;
          const year = selectMonth.value.year
          let d = dayjs(year+'/'+month+'/1')
          start = d.format('YYYY/M/D')
          //const d = dayjs(start).add(1,'M').subtract(1,'d')
          d = d.add(1,'M').subtract(1,'d')
          end = d.format('YYYY/M/D')
        } else if(rangeMode.value == "3"){
          let d = dayjs(selectRange.value[0].toLocaleDateString())
          start = d.format('YYYY/M/D')
          d = dayjs(selectRange.value[1].toLocaleDateString())
          end  = d.format('YYYY/M/D')
        }

        const post = {
          projects:selectProject.value,
          bushos:selectBusho.value,
          affiliations: selectAffiliation.value,
          start:start,
          end:end
        }

        var token = cookies.cookies.get('token');
        let res = await axios.get(Constains.tracerbaseURL+'/api/Codes',{headers:{
          'Authorization' :'Bearer '+token,
        }});
        if(res.status == 200){
          codeSource = []
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            codeSource.push({
              id:result['id'],
              code:result['code'],
              label:result['label']
            })
          }
        } 

        res = await axios.get(Constains.tracerbaseURL+'/api/Staffs',{headers:{
          'Authorization' :'Bearer '+token,
        }});
        let staffSource:any[] = []
        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            if(selectBusho.value.length > 0 && selectBusho.value.includes(result['busho'])){
              staffSource.push({
                id:result['id'],
                name:result['name'],
                busho:result['busho'],
                sort:result['sort'] == 0 ? null : result['sort'],
              })
            } else {
              staffSource.push({
                id:result['id'],
                name:result['name'],
                busho:result['busho'],
                sort:result['sort'] == 0 ? null : result['sort'],
              })
            }
          }
        }

        const projectPlans:any = []

        if(selectProject.value.length > 0){
          for await (const projectID of selectProject.value) {
            res = await axios.get(Constains.tracerbaseURL+'/api/ProjectPlans/'+projectID,{headers:{
            'Authorization' :'Bearer '+token,
            }});
            if(res.status == 200){
              let results = res.data;
              for(let i = 0; i < results.length; i++){
                let result = results[i];
                projectPlans.push({
                  id:result['id'],
                  projectid:result['projectid'],
                  code:result['code'],
                  plan:result['plan'],
                })
              }
            }
          }
        } else {
          res = await axios.get(Constains.tracerbaseURL+'/api/ProjectPlans',{headers:{
            'Authorization' :'Bearer '+token,
          }});
          if(res.status == 200){
            let results = res.data;
            for(let i = 0; i < results.length; i++){
              let result = results[i];
              projectPlans.push({
                id:result['id'],
                projectid:result['projectid'],
                code:result['code'],
                plan:result['plan'],
              })
            }
          }
        }

        var json:any[] = []
        res = await axios.post(Constains.tracerbaseURL+'/api/Outputs',JSON.stringify(post),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            if(result['project'] !== 0){
              const day = dayjs(result['date']).format('YYYY/MM/DD');
              json.push({
                staff:result['staff'],
                date:day,
                code:result['code'],
                achieve:result['achieve']+result['overtime'],
                project:result['project'],
                number:result['number'],
              })
            } 
          }
        } 
        
        const worksheet = xlsx.utils.json_to_sheet([])

        //重複した工事名称:projectを取り出す
        var projects:any[] = []
        for(let i =0 ; i < json.length;i++){
          if(!projects.includes(json[i].project)) projects.push(json[i].project)
        }

        //部署別に分ける場合にはここからstaffのbushoを元に分岐する

        //全ての日付を取得する
        var date:any[] = []
        for(let i =0 ; i < json.length;i++){
          if(!date.includes(json[i].date)) date.push(json[i].date)
        }
        date.sort(); //取得した日付をソート処理
        
        const baseColumn = 1;
        const daibunruiColumn = baseColumn + 1;
        const kubunColumn = daibunruiColumn + 1;
        const staffColumn = kubunColumn + 1;
        const planColumn = staffColumn + 1;
        const dateStartColumn = planColumn + 1;
        const startRow = 2;
        let currentRow = startRow;

        //const merges:any[] = [] //マージデータの埋め込み用.

        //記入を開始
        worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] = {
          v:'合計 / 作業時間（時間）',t:'s',w:'合計 / 作業時間（時間）',
          s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: headerColor }},
            },
            fill: { fgColor: { rgb: headerColor } } 
          }
        }
        worksheet[xlsx.utils.encode_col(baseColumn+1)+currentRow] = { 
          v:'',t:'s',w:'', 
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: headerColor }},
          },fill: { fgColor: { rgb: headerColor } } }}
        worksheet[xlsx.utils.encode_col(baseColumn+2)+currentRow] = { v:'',t:'s',w:'', 
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: headerColor }},
          },fill: { fgColor: { rgb: headerColor } } }}
        worksheet[xlsx.utils.encode_col(baseColumn+3)+currentRow] = { v:'',t:'s',w:'', 
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: headerColor }},
          },fill: { fgColor: { rgb: headerColor } } }}
          worksheet[xlsx.utils.encode_col(baseColumn+4)+currentRow] = { v:'',t:'s',w:'', 
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
          },fill: { fgColor: { rgb: headerColor } } }}
        currentRow++
        worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] = {v:'工事名称',t:'s',w:'工事名称',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        },fill: { fgColor: { rgb: headerColor } } }}
        worksheet[xlsx.utils.encode_col(baseColumn+1)+currentRow] = {v:'作業名',t:'s',w:'作業名',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        },fill: { fgColor: { rgb: headerColor } } }}
        worksheet[xlsx.utils.encode_col(baseColumn+2)+currentRow] = {v:'区分名',t:'s',w:'区分名',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        },fill: { fgColor: { rgb: headerColor } } }}
        worksheet[xlsx.utils.encode_col(baseColumn+3)+currentRow] = {v:'社員名',t:'s',w:'社員名',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        },fill: { fgColor: { rgb: headerColor } } }}
        worksheet[xlsx.utils.encode_col(planColumn)+currentRow] = {v:'計画',t:'s',w:'計画',
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
        },fill: { fgColor: { rgb: headerColor } } }}
        
        //合計 / 作業時間（時間）をマージ
        //rowの位置は0スタート。currentRowに対して-1 する必要がある
        //merges.push({ s: { r: startRow - 1, c: baseColumn }, e: { r: startRow - 1, c: baseColumn + 3 } }) 

        worksheet['!cols'] = [{wch:1.35},{wch:31.75},{wch:15},{wch:43.5},{wch:20},{wch:5},]

        //取得した日付をセルに登録し、その日付のセル位置を保持する
        var dateCell:any[] = []
        for(let i = 0; i < date.length;i++){
          const cell = xlsx.utils.encode_col(dateStartColumn + i )
          dateCell.push({date:date[i],cell:cell})
          const set = cell+currentRow
          worksheet[set] = {v:date[i].replace(/\/0/g,'/'),t:'s',w:date[i].replace(/\/0/g,'/'),s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: whiteFrame }},
              left:{style:'thin', color:{ rgb: i == 0 ? grayFrame : headerColor }},
            },
            fill: { fgColor: { rgb: headerColor } },
          }}
          worksheet[cell+(currentRow-1)] = {v:'',t:'s',w:'',s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: whiteFrame }},
              left:{style:'thin', color:{ rgb: i == 0 ? grayFrame : totalColor }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}
          worksheet['!cols'].push({wch:10})
        }

        worksheet['!cols'].push({wch:5})
        worksheet['!cols'].push({wch:14})
        worksheet['!cols'].push({wch:10})
        worksheet['!cols'].push({wch:14})
        worksheet['!cols'].push({wch:10})

        //総計を埋め込む
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length)+currentRow] = {v:'総計',t:'s',w:'総計',
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: grayFrame }},
          },
          fill: { fgColor: { rgb: totalColor } }
        }}
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length)+(currentRow-1)] = {v:'',t:'s',w:'',
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: grayFrame }},
            left:{style:'thin', color:{ rgb: totalColor }},
          },
          fill: { fgColor: { rgb: totalColor } }
        }}

        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1)+currentRow] = {v:'項目別実績集計',t:'s',w:'項目別実績集計',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1)+(currentRow-1)] = {v:'',t:'s',w:'',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: totalColor }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}

        //本数の合計を埋め込む
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 2)+currentRow] = {v:'本数合計',t:'s',w:'本数合計',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 2)+(currentRow-1)] = {v:'',t:'s',w:'',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: totalColor }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}

          worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 3)+currentRow] = {v:'項目別本数集計',t:'s',w:'項目別本数集計',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 3)+(currentRow-1)] = {v:'',t:'s',w:'',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: totalColor }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}
        

        //計画差を埋め込む
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+currentRow] = {v:'(計画-実績)',t:'s',w:'(計画-実績)',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+(currentRow-1)] = {v:'',t:'s',w:'',
          s:{ border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: totalColor }},
            },
            fill: { fgColor: { rgb: totalColor } }
          }}
        
        //総計の算出に使用
        const lastsum:any = {}
        
        let totalplan = 0; //総計の計画に使用
        let totalnumber = 0; //総計の本数に使用
        let totalplanDifference = 0 //総計の差異に使用
        
        for(let projectCount = 0; projectCount < projects.length; projectCount++){
          const allsum:any = {}
          currentRow++; //入力する行を設定
          //プロジェクトを入力
          const project = projectSource.value.filter(e=>e.value==projects[projectCount])[0]
          const projectLabel = project.label;

          worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] =  {v: projectLabel, t:'s', w:projectLabel ,
          s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: whiteFrame }},
            }
          }}
          //プロジェクトのデータだけを抽出
          const projectFiltered = json.filter(e => e.project == projects[projectCount])

          //重複した作業名:codeを取り出す
          var codes:any[] = []
          for(let i =0 ; i < projectFiltered.length; i++){
            if(!codes.includes(projectFiltered[i].code)) codes.push(projectFiltered[i].code)
          }
          codes.sort()

          //codesを基に大分類の一覧を取得する
          var daiCodes:any[] = []
          for(let codeCount = 0; codeCount < codes.length; codeCount++){
            const code = codes[codeCount]
            const DaiCode = get大分類(code); 
            if(!daiCodes.includes(DaiCode)) daiCodes.push(DaiCode)
          }

          let projectsumplan = 0
          let projectsumnumber = 0
          let projectplanDifference = 0
          let isUseProjectplanDifference = false

          //大分類の一覧を基準に入力していく
          for(let codeCount = 0 ;  codeCount < daiCodes.length; codeCount++) {
            //基準とする大分類
            const DaiCode = daiCodes[codeCount]
            //作業(DaiCode)に属する一覧をcodesから取得
            const codeList = codes.filter(e=>e==DaiCode.code||e.startsWith(DaiCode.code+'-')).reverse()

            //合計入力用
            const sumList:any = {}

            let sumplan = 0 //計画の合計入力用
            let codesumnumber = 0;
            let plancodeDifference = 0;
            let isUseplancodeDifference = false
            //codeList(作業に属する一覧)を入力していく
            for(let writeCount = 0 ; writeCount < codeList.length;writeCount++){
              const code = codeList[writeCount];

              //codeから同じ作業の一覧を取得
              const workFiltered = projectFiltered.filter(e=>e.code == code)
              //workFilteredから作業従事者の一覧を作成
              const staffs:any[] = []
              const staffIDcheck:number[] = [] //重複チェック用


              for(let i = 0; i <workFiltered.length;i++){
                if(!staffIDcheck.includes(workFiltered[i].staff)){
                  staffIDcheck.push(workFiltered[i].staff)
                  var staff = staffSource.filter(e => e.id==workFiltered[i].staff)//[0]
                  if(staff.length > 0)
                  staffs.push(staff[0])
                }
                //if(!staffs.includes(workFiltered[i].staff)) staffs.push(workFiltered[i].staff)
              }

              staffs.sort((a,b)=>{ return a.id - b.id })
              staffs.sort((a,b)=>{
                if(a.sort == null && b.sort == null) return 0
                if(a.sort == null) return 1
                if(b.sort == null) return -1
                return a.sort - b.sort
              })

              if(writeCount == 0){
                //作業名を入力
                worksheet[xlsx.utils.encode_col(daibunruiColumn )+currentRow] =  {v: DaiCode.label, t:'s', w:DaiCode.label }
                if(codeList.length > 1 || staffs.length > 1){
                  worksheet[xlsx.utils.encode_col(daibunruiColumn )+currentRow].s = { border:{ 
                    bottom:{style:'thin', color:{ rgb: whiteFrame }},
                    top:{style:'thin', color:{ rgb: grayFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                  }}
                }
              }
              
              let isKubunInput = false
              //codeが大分類でなければ区分を入力
              //大分類なら枠線を入力
              if(DaiCode.code!=code){
                const kubun = codeSource.filter(e=>e.code==code)[0].label
                worksheet[xlsx.utils.encode_col(kubunColumn ) + currentRow] =  {v: kubun, t:'s', w:kubun,s:{
                  border:{ 
                    bottom:{style:'thin', color:{ rgb: whiteFrame }},
                    top:{style:'thin', color:{ rgb: grayFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                  }
                } }
                isKubunInput = true
              } 

              //プロジェクトの計画を入力
              const planFiltered = projectPlans.filter(e=>e.projectid == project.value && e.code==code)
              const plan = planFiltered.length > 0 ? planFiltered[0].plan : 0
              if(plan) {
                sumplan += plan;
                totalplan += plan;
                projectsumplan += plan;
                plancodeDifference += plan;
                projectplanDifference += plan;
                totalplanDifference += plan;
                isUseProjectplanDifference = true
                isUseplancodeDifference = true
              }
              
              worksheet[xlsx.utils.encode_col( planColumn ) + currentRow] =  {v: planFiltered.length == 0 ? '' : plan, t:'s', w: planFiltered.length == 0 ? '' : plan,s:{
                  border:{ 
                    bottom:{style:'thin', color:{ rgb: whiteFrame }},
                    top:{style:'thin', color:{ rgb: grayFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                  }
                }
              }

              let planDifference = plan //計画と実績の差異入力用
              let fullsum = 0 //同じ区分における合計値
              let fullnumber = 0 //同じ区分における本数の合計
              const planDifferenceRow = currentRow
              
              for(let staffCount = 0; staffCount < staffs.length;staffCount++){
                //工事名称の枠を削除
                if(staffCount > 0 || writeCount > 0 || codeCount > 0){
                  worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] =  {
                    v: '', t:'s', w:'',
                    s: { border:{ 
                      top:{style:'thin', color:{ rgb: whiteFrame }},
                      left:{style:'thin', color:{ rgb: grayFrame }},
                      right:{style:'thin', color:{ rgb: grayFrame }},
                      bottom:{style:'thin', color:{ rgb: whiteFrame }},
                    }}
                  }
                }
                //スタッフ名を記入
                const staffname =  staffs[staffCount].name ? staffs[staffCount].name : ""
                worksheet[xlsx.utils.encode_col(staffColumn)+currentRow] =  {v: staffname, t:'s', w:staffname,
                s:{ border:{ 
                      top:{style:'thin', color:{ rgb: grayFrame }},
                      left:{style:'thin', color:{ rgb: grayFrame }},
                      right:{style:'thin', color:{ rgb: grayFrame }},
                      bottom:{style:'thin', color:{ rgb: whiteFrame }},
                    }}
               }
                
                if(staffCount > 0 || writeCount > 0){
                  //作業の枠の色を変える
                    worksheet[xlsx.utils.encode_col(daibunruiColumn) + currentRow] =  {v: '', t:'s', w:'',
                    s: { border:{
                      top:{style:'thin', color:{ rgb: whiteFrame }},
                      left:{style:'thin', color:{ rgb: grayFrame }},
                      right:{style:'thin', color:{ rgb: grayFrame }},
                      bottom:{style:'thin', color:{ rgb: whiteFrame }}
                    }} }
                }

                if(!isKubunInput){
                    //区分が未入力なので枠の色を変える前に空の値を入力
                    worksheet[xlsx.utils.encode_col(kubunColumn ) + currentRow] =  {v: '', t:'s', w:'', 
                      s:{
                        border:{ 
                          top:{style:'thin', color:{ rgb:  grayFrame }},
                          left:{style:'thin', color:{ rgb: grayFrame }},
                          right:{style:'thin', color:{ rgb: grayFrame }},
                          bottom:{style:'thin', color:{ rgb: whiteFrame  }}
                        }
                      }
                    }
                  }
                //区分名の枠の色を変える
                if(staffCount == 0 && staffs.length > 1 && staffCount < staffs.length -1 ){
                  worksheet[xlsx.utils.encode_col(kubunColumn )+currentRow].s = { border:{ 
                    top:{style:'thin', color:{ rgb:  grayFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                    bottom:{style:'thin', color:{ rgb: whiteFrame  }}
                  }}

                  //if(DaiCode == code) worksheet[xlsx.utils.encode_col(kubunColumn )+currentRow].s.border.top.color = { rgb: grayFrame }
                  //if(!isKubunInput){ worksheet[xlsx.utils.encode_col(kubunColumn )+currentRow].s.border.bottom.color = { rgb: grayFrame }}
                } else if(staffCount > 0 && staffCount < staffs.length){
                  worksheet[xlsx.utils.encode_col(kubunColumn )+currentRow]=  {v: '', t:'s', w:'' }
                  worksheet[xlsx.utils.encode_col(kubunColumn )+currentRow].s = { border:{ 
                    top:{style:'thin', color:{ rgb: whiteFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                    bottom:{style:'thin', color:{ rgb: whiteFrame  }}
                  }}
                  worksheet[xlsx.utils.encode_col(planColumn )+currentRow]=  {v: '', t:'s', w:'' }
                  worksheet[xlsx.utils.encode_col(planColumn )+currentRow].s = { border:{ 
                    top:{style:'thin', color:{ rgb: whiteFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                    bottom:{style:'thin', color:{ rgb: whiteFrame  }}
                  }}
                }
                
                
                let sum = 0; //総計値
                let numbersum = 0; //本数の合計
                
                //記入したスタッフの作業実績を抽出
                const achieves = workFiltered.filter(e=>e.staff==staffs[staffCount].id && e.code==code)
                for(let achieveCount = 0; achieveCount < achieves.length;achieveCount++){
                  const achieve = achieves[achieveCount];
                  //日付からカラムを取得
                  const dateColumn = dateCell.filter(e=>e.date == achieve.date)[0].cell
                  //実績を記入
                  const cell = dateColumn+currentRow
                  //同じ日に同じ作業をしていた場合のチェックを行う
                  let todayAchieve = 0
                  achieves.filter(e=>e.date==achieve.date).forEach(element => {
                    todayAchieve+=element.achieve
                  });

                  worksheet[cell] =  {v: todayAchieve, t:'n', w:todayAchieve,}

                  //worksheet[cell] =  {v: achieve.achieve, t:'n', w:achieve.achieve,}
                  sum+=achieve.achieve
                  fullsum+=achieve.achieve
                  numbersum += achieve.number
                  codesumnumber += achieve.number
                  totalnumber += achieve.number
                  projectsumnumber += achieve.number
                  fullnumber += achieve.number
                  if(planFiltered.length > 0) {
                    planDifference -= achieve.achieve
                    plancodeDifference -= achieve.achieve
                    projectplanDifference -= achieve.achieve
                    totalplanDifference -= achieve.achieve
                  }
                  if(!sumList[dateColumn]) sumList[dateColumn] = achieve.achieve
                  else sumList[dateColumn]+=achieve.achieve
                }

                //総計値を入力
                worksheet[xlsx.utils.encode_col( dateStartColumn + date.length )+currentRow] =  {v: sum, t:'n', w:sum,
                  s:{
                    border:{
                      top:{style:'thin', color:{ rgb: grayFrame }},
                      left:{style:'thin', color:{ rgb: grayFrame }},
                      right:{style:'thin', color:{ rgb: grayFrame }},
                      bottom:{style:'thin', color:{ rgb: totalColor }},
                    },
                    fill: { fgColor: { rgb: totalColor } }
                  }
                }

                //本数の合計値を入力
                worksheet[xlsx.utils.encode_col( dateStartColumn + date.length + 2 )+currentRow] =  {v: numbersum, t:'n', w:numbersum,
                   s:{
                    border:{
                      top:{style:'thin', color:{ rgb: grayFrame }},
                      left:{style:'thin', color:{ rgb: grayFrame }},
                      right:{style:'thin', color:{ rgb: grayFrame }},
                      bottom:{style:'thin', color:{ rgb: totalColor }},
                    },
                    fill: { fgColor: { rgb: totalColor } }
                  }
                }
                
                
                //区分名の枠の色を基準に枠の色を変える
                if(staffCount == 0 && staffs.length > 1 && staffCount < staffs.length -1 ){
                  worksheet[xlsx.utils.encode_col(dateStartColumn + date.length)+currentRow].s = { border:{ 
                    top:{style:'thin', color:{ rgb: grayFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                    bottom:{style:'thin', color:{ rgb: totalColor  }}
                  },
                    fill: { fgColor: { rgb: totalColor } }}

                    worksheet[xlsx.utils.encode_col(dateStartColumn + date.length+2)+currentRow].s = { border:{ 
                    top:{style:'thin', color:{ rgb: grayFrame }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                    bottom:{style:'thin', color:{ rgb: totalColor  }}
                  },
                    fill: { fgColor: { rgb: totalColor } }}
                } else if(staffCount > 0 && staffCount < staffs.length){
                  worksheet[xlsx.utils.encode_col(dateStartColumn + date.length)+currentRow].s = { border:{ 
                    top:{style:'thin', color:{ rgb: totalColor }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                    bottom:{style:'thin', color:{ rgb: totalColor  }}
                  },
                    fill: { fgColor: { rgb: totalColor } }}

                    worksheet[xlsx.utils.encode_col(dateStartColumn + date.length+2)+currentRow].s = { border:{ 
                    top:{style:'thin', color:{ rgb: totalColor }},
                    left:{style:'thin', color:{ rgb: grayFrame }},
                    right:{style:'thin', color:{ rgb: grayFrame }},
                    bottom:{style:'thin', color:{ rgb: totalColor  }}
                  },
                    fill: { fgColor: { rgb: totalColor } }}
                }
                
                currentRow++
              }
              //差異を入力
              for(let row = planDifferenceRow;row<=currentRow;row++){
                worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1)+row] = {v:'',t:'n',w:'',
                    s:{ border:{ 
                        top:{style:'thin', color:{ rgb: row == planDifferenceRow? grayFrame: totalColor }},
                        bottom:{style:'thin', color:{ rgb: grayFrame }},
                        right:{style:'thin', color:{ rgb: grayFrame }},
                        left:{style:'thin', color:{ rgb: grayFrame }},
                      },
                      fill: { fgColor: { rgb: totalColor } }
                    }}
                if(row == planDifferenceRow){
                  worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1)+row] = {v:fullsum,t:'n',w:fullsum,
                      s:{ border:{ 
                          top:{style:'thin', color:{ rgb: grayFrame }},
                          bottom:{style:'thin', color:{ rgb: totalColor  }},
                          right:{style:'thin', color:{ rgb: grayFrame }},
                          left:{style:'thin', color:{ rgb: grayFrame }},
                        },
                        fill: { fgColor: { rgb: totalColor } }
                      }}
                  worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 3)+row] = {v:fullnumber,t:'n',w:fullnumber,
                      s:{ border:{ 
                          top:{style:'thin', color:{ rgb: grayFrame }},
                          bottom:{style:'thin', color:{ rgb: totalColor  }},
                          right:{style:'thin', color:{ rgb: grayFrame }},
                          left:{style:'thin', color:{ rgb: grayFrame }},
                        },
                        fill: { fgColor: { rgb: totalColor } }
                      }}
                } else {
                  worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1)+row] = {v:'',t:'n',w:'',
                      s:{ border:{ 
                          top:{style:'thin', color:{ rgb: totalColor }},
                          bottom:{style:'thin', color:{ rgb: grayFrame }},
                          right:{style:'thin', color:{ rgb: grayFrame }},
                          left:{style:'thin', color:{ rgb: grayFrame }},
                        },
                        fill: { fgColor: { rgb: totalColor } }
                      }}
                  worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 3)+row] = {v:'',t:'n',w:'',
                      s:{ border:{ 
                          top:{style:'thin', color:{ rgb: totalColor }},
                          bottom:{style:'thin', color:{ rgb: grayFrame }},
                          right:{style:'thin', color:{ rgb: grayFrame }},
                          left:{style:'thin', color:{ rgb: grayFrame }},
                        },
                        fill: { fgColor: { rgb: totalColor } }
                      }}
                }

                if(planFiltered.length == 0){
                  worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+row] = {v:'',t:'n',w:'',
                    s:{ border:{ 
                        top:{style:'thin', color:{ rgb: row == planDifferenceRow? grayFrame: totalColor }},
                        bottom:{style:'thin', color:{ rgb: grayFrame }},
                        right:{style:'thin', color:{ rgb: grayFrame }},
                        left:{style:'thin', color:{ rgb: grayFrame }},
                      },
                      fill: { fgColor: { rgb: totalColor } }
                    }}
                  } else {
                    if(row == planDifferenceRow){
                      worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+row] = {v:planDifference,t:'n',w:planDifference,
                      s:{ border:{ 
                          top:{style:'thin', color:{ rgb: grayFrame }},
                          bottom:{style:'thin', color:{ rgb: planDifference >= 0 ? differenceColor : undifferenceColor }},
                          right:{style:'thin', color:{ rgb: grayFrame }},
                          left:{style:'thin', color:{ rgb: grayFrame }},
                        },
                        fill: { fgColor: { rgb: planDifference >= 0 ? differenceColor : undifferenceColor } }
                      }}
                    } else {
                      worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+row] = {v:'',t:'n',w:'',
                      s:{ border:{ 
                          top:{style:'thin', color:{ rgb: planDifference >= 0 ? differenceColor : undifferenceColor }},
                          bottom:{style:'thin', color:{ rgb: grayFrame }},
                          right:{style:'thin', color:{ rgb: grayFrame }},
                          left:{style:'thin', color:{ rgb: grayFrame }},
                        },
                        fill: { fgColor: { rgb: planDifference >= 0 ? differenceColor : undifferenceColor } }
                      }}
                    }
                  }
              }
            }
            //大分類が一つ終わったので合計値を算出
            worksheet[xlsx.utils.encode_col(baseColumn )+currentRow] =  {
              v: '', t:'s', w:'',
              s:  { border:{ 
                top:{style:'thin', color:{ rgb: whiteFrame }},
                bottom:{style:'thin', color:{ rgb: whiteFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }}
              }}
            }
            worksheet[xlsx.utils.encode_col(baseColumn + 1 )+currentRow] =  {
              v: DaiCode.label+' 合計', t:'s', w:DaiCode.label+' 合計',
              s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: sumColor }},
              },
              fill:{fgColor:{rgb:sumColor}}
            }}
            worksheet[xlsx.utils.encode_col(baseColumn + 2 )+currentRow] =  {
              v: '', t:'s', w:'',
              s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: sumColor }},
                right:{style:'thin', color:{ rgb: sumColor }},
              },
              fill:{fgColor:{rgb:sumColor}}}}
            worksheet[xlsx.utils.encode_col(baseColumn + 3 )+currentRow] =  {
            v: '', t:'s', w:'',
            s:  { border:{ 
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: sumColor }},
              right:{style:'thin', color:{ rgb: grayFrame }},
            },
            fill:{fgColor:{rgb:sumColor}}}}
            
            //合計の列の色を全て変える
            for(let changeColorColumn = 0; changeColorColumn < date.length; changeColorColumn++ ){
              worksheet[xlsx.utils.encode_col(dateStartColumn + changeColorColumn )+currentRow] =  {
              v: '', t:'s', w:'',
              s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: changeColorColumn == 0 ? grayFrame:sumColor }},
                right:{style:'thin', color:{ rgb: sumColor }},
                },
                fill:{fgColor:{rgb:sumColor}}
              }}
            }

            let sum = 0;
            const sumKey = Object.keys(sumList)
            sumKey.forEach(key => {
              worksheet[key+currentRow] =  {
                v: sumList[key], t:'n', w:sumList[key],
                s:  { border:{ 
                  top:{style:'thin', color:{ rgb: grayFrame }},
                  bottom:{style:'thin', color:{ rgb: grayFrame }},
                  left:{style:'thin', color:{ rgb: lightgrayFrame }},
                  right:{style:'thin', color:{ rgb: sum !=0 ? sumColor : grayFrame }},
                },
                fill:{fgColor:{rgb:sumColor}}}
              } 
              sum += sumList[key]
              if(!allsum[key]) allsum[key] = sumList[key]
              else allsum[key] += sumList[key] 
            });

            //大分類の計画の合計を入力 
            worksheet[xlsx.utils.encode_col(planColumn )+currentRow] =  {
              v: isUseplancodeDifference ? sumplan : '', t:'s', w:isUseplancodeDifference ? sumplan : '',
              s:  { 
                border:{ 
                  top:{style:'thin', color:{ rgb: grayFrame }},
                  bottom:{style:'thin', color:{ rgb: grayFrame }},
                  left:{style:'thin', color:{ rgb: whiteFrame }},
                  right:{style:'thin', color:{ rgb: grayFrame }},
                },
               fill:{fgColor:{rgb:sumColor}}
            }
          }

            //総計に合計を入力
            worksheet[xlsx.utils.encode_col(dateStartColumn + date.length)+currentRow] =  {v: sum, t:'n', w:sum,s:{
              border:{
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
               fill:{fgColor:{rgb:totalColor}}
            } }

            worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1)+currentRow] =  {v: sum, t:'n', w:sum,s:{
              border:{
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
               fill:{fgColor:{rgb:totalColor}}
            } }


            //本数の合計を入力
            worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 2)+currentRow] =  {v: codesumnumber, t:'n', w:codesumnumber,s:{
              border:{
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
               fill:{fgColor:{rgb:totalColor}}
            } }
            worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 3)+currentRow] =  {v: codesumnumber, t:'n', w:codesumnumber,s:{
              border:{
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
               fill:{fgColor:{rgb:totalColor}}
            } }
            //大分類の計画の差異を入力
            worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+currentRow] =  {v: isUseplancodeDifference ?  plancodeDifference : '', t:'n', w:isUseplancodeDifference ?  plancodeDifference : '',s:{
              border:{
                top:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
               fill:{fgColor:{rgb: !isUseplancodeDifference ? totalColor : plancodeDifference >= 0 ? differenceColor : undifferenceColor}}
            } }

            currentRow++
          }
          worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] =  {
            v: projectLabel+' 集計', t:'s', w:projectLabel+' 集計',
            s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: projectsumColor }},
              }, 
              fill:{fgColor:{rgb:projectsumColor}}
            }}
           worksheet[xlsx.utils.encode_col(baseColumn+1)+currentRow] =  {
            v: '', t:'s', w:'',
            s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: projectsumColor }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: projectsumColor }},
              }, 
              fill:{fgColor:{rgb:projectsumColor}}}
           }
           worksheet[xlsx.utils.encode_col(baseColumn+2)+currentRow] =  {
            v: '', t:'s', w:'',
            s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: projectsumColor }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: projectsumColor }},
              }, 
              fill:{fgColor:{rgb:projectsumColor}}}
           }
           worksheet[xlsx.utils.encode_col(baseColumn+3)+currentRow] =  {
            v: '', t:'s', w:'',
            s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: whiteFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              }, 
              fill:{fgColor:{rgb:projectsumColor}}}
           }
           worksheet[xlsx.utils.encode_col(baseColumn+4)+currentRow] =  {
            v: isUseProjectplanDifference ? projectsumplan : '', t:'s', w:isUseProjectplanDifference ? projectsumplan : '',
            s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: whiteFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              }, 
              fill:{fgColor:{rgb:projectsumColor}}}
           }
        for(let i = 0; i< date.length; i++){
          worksheet[xlsx.utils.encode_col(dateStartColumn+i)+currentRow] =  {v: '', t:'s', w:'',s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: i == 0 ? grayFrame : projectsumColor }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: projectsumColor }},
              },
              fill:{fgColor:{rgb:projectsumColor}}
            }
          }
        }
          
          let sum = 0;
          const sumKey = Object.keys(allsum)
          sumKey.forEach(key => {
            sum += allsum[key]
            worksheet[key+currentRow] =  {
              v: allsum[key], t:'n', w:allsum[key],
              s:  { border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: whiteFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              }, 
              fill:{fgColor:{rgb:projectsumColor}}}
            }
            if(!lastsum[key]) lastsum[key] = allsum[key]
              else lastsum[key] += allsum[key]
          });
          worksheet[xlsx.utils.encode_col(dateStartColumn + date.length)+currentRow] =  {v: sum, t:'n', w:sum,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          } }

          worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1 )+currentRow] =  {v: sum, t:'n', w:sum,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          } }

          worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 2)+currentRow] =  {v: projectsumnumber, t:'n', w:projectsumnumber,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          } }

          worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 3)+currentRow] =  {v: projectsumnumber, t:'n', w:projectsumnumber,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          } }

          worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+currentRow] =  {v: isUseProjectplanDifference ? projectplanDifference : '', t:'n', w:isUseProjectplanDifference ? projectplanDifference : '',s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb: !isUseProjectplanDifference ? totalColor: projectplanDifference >= 0 ? differenceColor:undifferenceColor}}
          } }
        }

        currentRow++
        worksheet[xlsx.utils.encode_col(baseColumn)+currentRow] =  {v: '総計', t:'s', w:'総計',s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: totalColor }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
        }
        worksheet[xlsx.utils.encode_col(baseColumn+1)+currentRow] =  {v: '', t:'s', w:'',s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: totalColor }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: totalColor }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
        }
        worksheet[xlsx.utils.encode_col(baseColumn+2)+currentRow] =  {v: '', t:'s', w:'',s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: totalColor }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
        }
        worksheet[xlsx.utils.encode_col(baseColumn+3)+currentRow] =  {v: '', t:'s', w:'',s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
        }

        worksheet[xlsx.utils.encode_col(baseColumn+4)+currentRow] =  {v: totalplan, t:'s', w:totalplan,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
        }

        for(let i = 0; i< date.length; i++){
          worksheet[xlsx.utils.encode_col(dateStartColumn+i)+currentRow] =  {v: '', t:'s', w:'',s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: i == 0 ? grayFrame : totalColor }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: totalColor }},
              },
              fill:{fgColor:{rgb:totalColor}}
            }
          }
        }
        
        //総計を入力
        let sum = 0;
        const sumKey = Object.keys(lastsum)
        sumKey.forEach(key => {
          sum += lastsum[key]
          worksheet[key+currentRow] =  {
            v: lastsum[key], t:'n', w:lastsum[key],s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: totalColor }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: totalColor }},
              },
              fill:{fgColor:{rgb:totalColor}}
            }
          }
        });

        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length)+currentRow] =  {v: sum, t:'n', w:sum,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
       }

       worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 1)+currentRow] =  {v: sum, t:'n', w:sum,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
       }

       worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 2)+currentRow] =  {v: totalnumber, t:'n', w:totalnumber,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
       }

       worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 3)+currentRow] =  {v: totalnumber, t:'n', w:totalnumber,s:{
            border:{ 
                top:{style:'thin', color:{ rgb: grayFrame }},
                left:{style:'thin', color:{ rgb: grayFrame }},
                bottom:{style:'thin', color:{ rgb: grayFrame }},
                right:{style:'thin', color:{ rgb: grayFrame }},
              },
            fill:{fgColor:{rgb:totalColor}}
          }
       }

       worksheet[xlsx.utils.encode_col(dateStartColumn + date.length + 4)+currentRow] =  {v: totalplanDifference, t:'n', w: totalplanDifference,s:{
        border:{
          top:{style:'thin', color:{ rgb: grayFrame }},
          bottom:{style:'thin', color:{ rgb: grayFrame }},
          left:{style:'thin', color:{ rgb: grayFrame }},
          right:{style:'thin', color:{ rgb: grayFrame }},
        },
          fill:{fgColor:{rgb:  totalplanDifference >= 0 ? differenceColor : undifferenceColor}}
      } }

        //表示範囲を設定.これを入れないとデータを入れても表示されない
        const ref = "A1:"+ xlsx.utils.encode_col(dateStartColumn + date.length + 4) + currentRow;
        worksheet['!ref'] = ref

        //worksheet['!merges'] = merges; //マージ処理を反映
        //worksheet['!cols'] = [{wch:1.35},{wch:31.75},{wch:15},{wch:43.5},{wch:20},] //カラムの横幅を変更
        
        
        const workbook = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(workbook,worksheet,"集計")
        let sheetname = '作業日報集計'
        if(rangeMode.value == "2"){
          const month = selectMonth.value.month + 1;
          const year = selectMonth.value.year
          sheetname += year+'年'+month+'月'
        } else if(rangeMode.value == "3"){
          let d = dayjs(selectRange.value[0].toLocaleDateString())
          start = d.format('YYYY/M/D')
          d = dayjs(selectRange.value[1].toLocaleDateString())
          end  = d.format('YYYY/M/D')
          sheetname += start+'-'+end
        }
        
        if(selectBusho.value.length>0){
          sheetname+='('
          selectBusho.value.forEach(busho => {
            sheetname+=bushoSource.value.filter(e=>e.value==busho)[0].label+','
          });
          sheetname = sheetname.substring(0,sheetname.length-1)
          sheetname+=')'
        }

        xlsx.writeFile(workbook,sheetname+".xlsx", { compression: true });
      }

      const zangyou = async() => {
        let start = ''
        let end = ''
        if(rangeMode.value == "2"){
          if(selectMonth.value===undefined){
            Modal.error({
              title: 'エラー',
              content: '出力する月が選択されていません.',
            });
            return
          }
          const month = selectMonth.value.month + 1;
          const year = selectMonth.value.year
          let d = dayjs(year+'/'+month+'/1')
          start = d.format('YYYY/M/D')
          //const d = dayjs(start).add(1,'M').subtract(1,'d')
          d = d.add(1,'M').subtract(1,'d')
          end = d.format('YYYY/M/D')
        } else if(rangeMode.value == "3"){
          let d = dayjs(selectRange.value[0].toLocaleDateString())
          start = d.format('YYYY/M/D')
          d = dayjs(selectRange.value[1].toLocaleDateString())
          end  = d.format('YYYY/M/D')
        }

        var token = cookies.cookies.get('token');

        let res = await axios.get(Constains.tracerbaseURL+'/api/Staffs',{headers:{
          'Authorization' :'Bearer '+token,
        }});
        let staffs:any[] = []
        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            if(selectBusho.value.length > 0 && selectBusho.value.includes(result['busho'])){
              staffs.push({
                id:result['id'],
                staffcode:result['email'],
                name:result['name'],
                busho:result['busho'],
                affiliation:result['affiliation'],
                sort:result['sort'] == 0 ? null : result['sort'],
              })
            } else if(selectBusho.value.length == 0) {
              staffs.push({
                id:result['id'],
                staffcode:result['email'],
                name:result['name'],
                busho:result['busho'],
                affiliation:result['affiliation'],
                sort:result['sort'] == 0 ? null : result['sort'],
              })
            }
          }
        }

        staffs.sort((a,b)=>{ return a.id - b.id })
        staffs.sort((a,b)=>{
          if(a.sort == null && b.sort == null) return 0
          if(a.sort == null) return 1
          if(b.sort == null) return -1
          return a.sort - b.sort
        })

        const post = {
          projects:selectProject.value,
          bushos:selectBusho.value,
          affiliations: selectAffiliation.value,
          start:start,
          end:end
        }

        var acheives:any[] = []
        res = await axios.post(Constains.tracerbaseURL+'/api/Outputs',JSON.stringify(post),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});

        if(res.status == 200){
          let results = res.data;
          for(let i = 0; i < results.length; i++){
            let result = results[i];
            const day = dayjs(result['date']).format('YYYY/MM/DD');
            acheives.push({
              staff:result['staff'],
              date:day,
              code:result['code'],
              achieve:result['achieve'],
              overtime:result['overtime'],
              project:result['project']
            })
          }
        } 

        //全ての日付を取得する
        var date:any[] = []
        for(let i =0 ; i < acheives.length;i++){
          if(!date.includes(acheives[i].date)) date.push(acheives[i].date)
        }
        date.sort();

        const bushoColumn = 0;
        const staffcodeColumn = bushoColumn+1
        const nameColumn = staffcodeColumn + 1;
        const dateStartColumn = nameColumn + 1;
        const startRow = 1;
        let currentRow = startRow;

        const worksheet = xlsx.utils.json_to_sheet([])
        //記入を開始
        worksheet[xlsx.utils.encode_col(bushoColumn )+currentRow] = {
          v:'部署',t:'s',w:'部署',
          s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: headerColor }},
            },
            fill: { fgColor: { rgb: headerColor } } 
          }
        }
        worksheet[xlsx.utils.encode_col(staffcodeColumn)+currentRow] = { 
          v:'staffcode',t:'s',w:'staffcode', 
          s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: headerColor }},
          },fill: { fgColor: { rgb: headerColor } } }}
        worksheet[xlsx.utils.encode_col(nameColumn)+currentRow] = { v:'氏名',t:'s',w:'氏名', 
        s:{ border:{ 
            top:{style:'thin', color:{ rgb: grayFrame }},
            bottom:{style:'thin', color:{ rgb: grayFrame }},
            right:{style:'thin', color:{ rgb: headerColor }},
          },fill: { fgColor: { rgb: headerColor } } }}

        worksheet['!cols'] = [{wch:20},{wch:10},{wch:31.75},] //カラムの横幅を変更

        //取得した日付をセルに登録し、その日付のセル位置を保持する
        var dateCell:any[] = []
        for(let i = 0; i < date.length;i++){
          const cell = xlsx.utils.encode_col(dateStartColumn + i )
          dateCell.push({date:date[i],cell:cell})
          const set = cell+currentRow
          worksheet[set] = {v:date[i].replace(/\/0/g,'/'),t:'s',w:date[i].replace(/\/0/g,'/'),s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: i == 0 ? grayFrame : headerColor }},
            },
            fill: { fgColor: { rgb: headerColor } }
          }}
          worksheet['!cols'].push({wch:10})
        }
        worksheet[xlsx.utils.encode_col(dateStartColumn + date.length )+currentRow] = {v:'累計',t:'s',w:'累計',s:{
            border:{
              top:{style:'thin', color:{ rgb: grayFrame }},
              bottom:{style:'thin', color:{ rgb: grayFrame }},
              right:{style:'thin', color:{ rgb: grayFrame }},
              left:{style:'thin', color:{ rgb: grayFrame }},
            },
            fill: { fgColor: { rgb: headerColor } }
          }}
        worksheet['!cols'].push({wch:10})

        staffs.forEach(staff => {
          const staffid = staff.id
          const staffcode = staff.staffcode
          const staffname = staff.name
          if(selectBusho.value.includes(staff.busho) || selectBusho.value.length == 0){
            if(selectAffiliation.value.includes(staff.affiliation) ||  selectAffiliation.value.length == 0){
              currentRow++
              const busho = bushoSource.value.some(e=>e.value == staff.busho) ? bushoSource.value.filter(e=>e.value == staff.busho)[0].label : '';
              const bushoType = bushoSource.value.some(e=>e.value == staff.busho) ? bushoSource.value.filter(e=>e.value == staff.busho)[0].edittype : 0;
              worksheet[xlsx.utils.encode_col(bushoColumn )+currentRow] = {v:busho,t:'s',w:busho,}
              worksheet[xlsx.utils.encode_col(staffcodeColumn )+currentRow] = {v:staffcode,t:'s',w:staffcode,}
              worksheet[xlsx.utils.encode_col(nameColumn )+currentRow] = {v:staffname,t:'s',w:staffname,}

              const userachieves = acheives.filter(e=>e.staff== staffid);
              let sum = 0;
              date.forEach(day => {
                const dayAchieves = userachieves.filter(e=>e.date==day)
                if(dayAchieves.length > 0){
                  let overtime = 0;
                  if(bushoType == 0){
                    const overtimes = dayAchieves.filter(e=>e.overtime > 0)
                    overtimes.forEach(achieve =>{ overtime+=achieve.overtime })
                  } else {
                    dayAchieves.forEach(achieve=>{ overtime+=achieve.achieve })
                    overtime -= 8
                    if(overtime < 0) overtime = 0
                  }
                  //日付からカラムを取得
                  const dateColumn = dateCell.filter(e=>e.date == day)[0].cell
                  //実績を記入
                  const cell = dateColumn+currentRow
                  worksheet[cell] =  {v: overtime, t:'s', w:overtime,}
                  sum += overtime
              }});
              worksheet[xlsx.utils.encode_col(dateStartColumn + date.length )+currentRow] =  {v: sum, t:'s', w:sum,} 
            }
          }
        });
        currentRow++

        //表示範囲を設定.これを入れないとデータを入れても表示されない
        const ref = "A1:"+ xlsx.utils.encode_col(dateStartColumn + date.length) + currentRow;
        worksheet['!ref'] = ref

        //worksheet['!merges'] = merges; //マージ処理を反映
        //worksheet['!cols'] = [{wch:20},{wch:10},{wch:31.75},] //カラムの横幅を変更
        
        const workbook = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(workbook,worksheet,"残業時間")
        let sheetname = '全範囲'
        if(rangeMode.value == "2"){
          const month = selectMonth.value.month + 1;
          const year = selectMonth.value.year
          sheetname = year+'年'+month+'月'
        } else if(rangeMode.value == "3"){
          let d = dayjs(selectRange.value[0].toLocaleDateString())
          start = d.format('YYYY/M/D')
          d = dayjs(selectRange.value[1].toLocaleDateString())
          end  = d.format('YYYY/M/D')
          sheetname = start+'-'+end
        }
        sheetname+='残業'
        if(selectBusho.value.length>0){
          sheetname+='('
          selectBusho.value.forEach(busho => {
            sheetname+=bushoSource.value.filter(e=>e.value==busho)[0].label+','
          });
          sheetname = sheetname.substring(0,sheetname.length-1)
          sheetname+=')'
        }

        xlsx.writeFile(workbook,sheetname+".xlsx", { compression: true });
      }

      return {
        nippou,
        projectSource,
        selectProject,
        bushoSource,
        selectBusho,
        affiliationSource,
        selectAffiliation,
        rangeMode,
        selectMonth,
        selectRange,
        zangyou,
      };
    },
  });
  